import { useEffect } from 'react';

/**
 * A hook that executes a callback function when the component unmounts.
 * 
 * This hook captures the callback at the time of the first render and executes it
 * during the cleanup phase of the component lifecycle (unmounting).
 * 
 * @param {Function} callback - The function to be executed on component unmount
 * 
 * @example
 * // Basic usage
 * useOnUnmount(() => {
 *   console.log('Component unmounted');
 * });
 * 
 * @example
 * // If you need to use changing values, capture them explicitly
 * useOnUnmount(() => {
 *   const currentId = id; // Capture current value
 *   return () => console.log(`Component with ID ${currentId} unmounted`);
 * });
 * 
 * @note
 * The callback function captures variables as they exist during the first render.
 * If you need to access the latest values of changing variables, use a standard
 * useEffect with the appropriate dependencies instead.
 */
export default function useOnUnmount(callback) {
  useEffect(() => {
    // Store the callback function at the time the effect is created (first render)
    const unmountCallback = callback;
    
    // Return a cleanup function that will be executed when the component unmounts
    return () => unmountCallback();
  }, []);
  // Empty dependency array ensures this effect runs only once on mount
  // We intentionally omit 'callback' from dependencies to ensure the cleanup
  // function is not recreated on every render
}