import React, { memo } from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

const FicheContratFormFooter = ({
  onSubmit,
  disabledSubmit,
  goBackHistory,
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <FormSubmitButtons
        onSubmit={onSubmit}
        submitLabel={'Sauvegarder'}
        onCancel={goBackHistory}
        disabledSubmit={disabledSubmit}
      />
    </Box>
  );
};

FicheContratFormFooter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool.isRequired,
  goBackHistory: PropTypes.func.isRequired,
};

export default memo(FicheContratFormFooter);
