import React from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Error';

import { clearProprieteLink, setSyncError, syncContrat } from '@/slices/sylvalibreSlice';
import useOnUnmount from '@/hooks/useOnUnmount';
import { fromISODateTime } from '@/utils/date';
import { setMessage } from '@/slices/messageSlice';

import SylvalibreProprieteLink from '@/features/shared/sylvalibre-link/SylvalibreProprieteLink';
import { fetchFilteredItems } from '@/slices/itemSlice';

export default function SylvalibreContratLink({
  propriete,
  contratProprietaire,
}) {
  const dispatch = useDispatch();

  const proprieteLink = useSelector(
    (state) => state.sylvalibreReducer.proprieteLink
  );

  const isLoading = useSelector((state) => state.isLoadingReducer.isLoading);

  useOnUnmount(() => {
    dispatch(clearProprieteLink());
    dispatch(setSyncError(null));
  });

  const onSyncUgClick = () => {
    if (!contratProprietaire?.id) {
      dispatch(
        setMessage(
          "Veuillez d'abord sauvegarder la fiche contrat avant de synchroniser."
        )
      );
      return;
    }

    dispatch(syncContrat({ contratId: contratProprietaire?.id }));
  };

  const syncError = useSelector((state) => state.sylvalibreReducer.syncError);

  const latestSyncDatetime = fromISODateTime(
    contratProprietaire?.sylvalibre_latest_sync_datetime,
    true
  );

  const onSylvalibreProprieteLinkClear = () => {
    dispatch(
      fetchFilteredItems({
        itemType: 'unites_gestion',
        filter: { item_type: 'contrat_proprietaire', item_id: contratProprietaire?.id },
      })
    );
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item xs={12} md={8} lg={5}>
        {propriete && (
          <SylvalibreProprieteLink propriete={propriete} onClear={onSylvalibreProprieteLinkClear} />
        )}
      </Grid>
      {proprieteLink && (
        <Grid item xs='auto'>
          <Button
            onClick={onSyncUgClick}
            variant='contained'
            startIcon={<SyncIcon />}
            disabled={isLoading}
          >
            Synchroniser le contrat
          </Button>
          {syncError ? (
            <Box
              p={1}
              mt={1}
              border={1}
              borderColor='error.main'
              borderRadius={1}
              color='error.main'
              display='flex'
              alignItems='center'
              maxWidth={500}
            >
              <ErrorIcon />
              <Typography ml={1}>{syncError}</Typography>
            </Box>
          ) : (
            <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
              {contratProprietaire?.sylvalibre_latest_sync_datetime
                ? `Synchronisé ${latestSyncDatetime}`
                : 'Non synchronisé'}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

SylvalibreContratLink.propTypes = {
  propriete: PropTypes.object,
  contratProprietaire: PropTypes.object,
};
