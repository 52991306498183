import React, { useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import InfoIcon from '@mui/icons-material/Info';

export default function EtatAvancementLegende({ choices }) {
  const etatAvancementCouleursRows = useMemo(() => {
    const etatAvancementCouleurs = {};
    choices?.etat.forEach((etat) => {
      if (!etatAvancementCouleurs[etat.couleur]) {
        etatAvancementCouleurs[etat.couleur] = [];
      }
      etatAvancementCouleurs[etat.couleur].push(etat.nom);
    });
    return Object.entries(etatAvancementCouleurs);
  }, [choices]);

  const [open, setOpen] = useState(false);

  const handleOpenLegend = () => setOpen(true);
  const handleCloseLegend = () => setOpen(false);

  return (
    <>
      <Button
        onClick={handleOpenLegend}
        fullWidth={false}
        variant='outlined'
        startIcon={<InfoIcon />}
      >
        Légende état avancement des contrats
      </Button>
      <Dialog open={open} onClose={handleCloseLegend} maxWidth='xs'>
        <DialogTitle>Légende état avancement des contrats</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Couleur</TableCell>
                  <TableCell>Avancement</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {etatAvancementCouleursRows.map(([couleur, etats]) => (
                  <TableRow key={couleur}>
                    <TableCell
                      sx={{
                        color: couleur,
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        py: 0.5,
                      }}
                    >
                      XXXX
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>{etats.join(', ')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

EtatAvancementLegende.propTypes = {
  choices: PropTypes.object,
};
