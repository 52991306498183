import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import DiagnosticNeosylvaForm from '@/features/diagnostic-neosylva/DiagnosticNeosylvaForm';

import Container from '@mui/material/Container';

export default function DiagnosticNeosylvaEdit() {

  const [searchParams] = useSearchParams();

  const contratId = useMemo(() => searchParams.get('contrat_id'));
  return (
    <Container component='main' maxWidth={false}>
      <DiagnosticNeosylvaForm edition contratId={contratId} />
    </Container>
  );
}
