import React, { useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import FicheContratForm from '@/features/fiche-contrat/FicheContratForm';

import Container from '@mui/material/Container';

export default function FicheContrat() {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const proprieteId = useMemo(() => searchParams.get('propriete_id'));
  const defaultTab = useMemo(() => searchParams.get('tab'));
  const contratId = useMemo(() => params.id);

  const emptyItem = {
    code_chantier: null,
    proprietaires_list: [],
    unites_gestion_list: [],
    date_signature_contrat_seing_prive: null,
    date_fin_contrat: null,
    date_signature_acte_notarie: null,
    montant_forfaitaire: null,
    pourcentage_services_environnementaux: null,
    montant_total_previsionnel_operations: null,
    date_paiement_forfaitaire: null,
    gestionnaire: null,
    etat: null,
    propriete: null,
    referent_technique: null,
    itineraires_sylvicoles_pressentis: null,
    date_premiere_visite_neosylva: null,
    sharepoints_list: [],
    files_list: [],
  };

  const item = useItem(contratId, 'contrats_proprietaires', emptyItem);

  return (
    <Container component='main' maxWidth={false}>
      {item && <FicheContratForm contratProprietaire={item} contratId={contratId} proprieteId={proprieteId} defaultTab={defaultTab} />}
    </Container>
  );
}

FicheContrat.propTypes = {};