import React from 'react';

import PropTypes from 'prop-types';

import StatefulDataGrid from '@/components/StatefulDataGrid';
import TabPanel from '@/components/TabPanel';

import ProprietesTabActions from '@/features/tableau-bord-cmf/ProprietesTabActions';

export default function ProprietesTab({
  tabValue,
  items,
  columns,
  choices,
  ...props
}) {
  return (
    <TabPanel currentValue={tabValue} index={0}>
      <ProprietesTabActions choices={choices} />
      <StatefulDataGrid
        boxSx={{ my: 2 }}
        columns={columns}
        rows={items}
        {...props}
      />
    </TabPanel>
  );
}

ProprietesTab.propTypes = {
  tabValue: PropTypes.number,
  items: PropTypes.array,
  columns: PropTypes.array,
  choices: PropTypes.object,
};
