import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import useOnUnmount from '@/hooks/useOnUnmount';

import { clearFilteredItems, fetchFilteredItems } from '@/slices/itemSlice';

/**
 * Custom hook that provides a function to fetch multiple types of filtered items.
 *
 * This hook returns a memoized function that can be called to fetch multiple types
 * of items with a specific filter, and cleans up filtered items on component unmount.
 *
 * @returns {Function} A memoized function that accepts itemTypes and filter parameters
 *                    and returns a Promise that resolves when all requests are complete
 */
export default function useFetchFilteredItems() {
  const dispatch = useDispatch();

  // Clean up filtered items when component unmounts
  useOnUnmount(() => {
    dispatch(clearFilteredItems());
  });

  // Return a memoized function to fetch filtered items
  return useCallback(
    ({ itemTypes, filter }) => {
      const promisePool = [];

      // Create a promise for each item type
      itemTypes.forEach((itemType) => {
        promisePool.push(dispatch(fetchFilteredItems({ itemType, filter })));
      });

      // Wait for all promises to resolve or reject
      return Promise.all(promisePool);
    },
    [dispatch]
  );
}
