import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useConditionalRequired from '@/hooks/useConditionalRequired';
import useItems from '@/hooks/useItems';

import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import CiviliteRadioGroupField from '@/components/controlled-fields/CiviliteRadioGroupField';
import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import AutoCompleteTextField from '@/components/controlled-fields/text-fields/AutoCompleteTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';
import UpperCaseTextField from '@/components/controlled-fields/text-fields/UpperCaseTextField';

import AdresseField from '@/features/shared/AdresseField';
import DepartementField from '@/features/shared/DepartementField';
import EntrepriseField from '@/features/shared/EntrepriseField';
import ContactAutocompleteSearchField from '@/features/shared/ContactAutocompleteSearchField';

import useIsGestionnaire from '@/hooks/useIsGestionnaire';

const EXISTING_CONTACT_MODE = 0;
const NEW_CONTACT_MODE = 1;

export default function ProprietaireFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
  proprietairesList,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const isGestionnaire = useIsGestionnaire();

  const entreprises = isGestionnaire ? [] : useItems('entreprises');
  const usersList = isGestionnaire ? [] : useItems('users');
  const choicesContact = useChoices('contacts');
  const choicesProprietaire = useChoices('proprietaires');


  const onProprietaireSubmit = (prop) => {

    if (prop.personnalite_juridique?.code !== 'PM') {
      prop.raison_sociale = null;
      prop.siret = null;
      prop.adresse_siege = null;
      prop.code_postal_siege = null;
      prop.commune_siege = null;
    }

    return onSubmit(prop);
  };

  const ProprietaireForm = ({ currentItem }) => {
    const isEdition = useMemo(() => !!currentItem?.id, [currentItem]);

    const [proprietaire, setProprietaire] = useState(currentItem);
    const [contact, setContact] = useState(null);

    const {
      control,
      handleSubmit,
      setValue,
      reset,
      trigger,
      formState: { isValid },
    } = useForm({
      defaultValues: currentItem || {
        personnalite_juridique: null,
        contact: {
          civilite: null,
          nom: null,
          prenom: null,
          date_naissance: null,
          telephone_fixe: null,
          telephone_portable: null,
          email: null,
          adresse: null,
          commune: null,
          code_postal: null,
          departement: null,
          pays: null,
          entreprises_list: [],
          profession: null,
          contact_neosylva: null,
        },
      },
      values: proprietaire,
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    const [mode, setMode] = useState(
      isEdition ? EXISTING_CONTACT_MODE : NEW_CONTACT_MODE
    );

    const personnaliteJuridiqueWatch = useWatch({
      control,
      name: 'personnalite_juridique',
    });

    const conditionalRequiredFields = [
      ['contact.telephone_fixe'],
      ['contact.telephone_portable'],
      ['contact.email'],
      ['contact.adresse', 'contact.commune', 'contact.code_postal'],
    ];

    const requiredMapper = useConditionalRequired(
      conditionalRequiredFields,
      control,
      trigger
    );

    // Reset form & proprietaire on mode switch (only when creating a new proprietaire)
    useEffect(() => {
      if (isEdition) {
        return;
      }
      reset();
      setProprietaire(null);
    }, [mode]);

    useEffect(() => {
      if (!contact) {
        return;
      }

      setProprietaire({ contact });
    }, [contact]);

    return (
      <>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Néosylva
            </Typography>
            <Button
              autoFocus
              color='inherit'
              disabled={
                !isValid || (mode === EXISTING_CONTACT_MODE && !proprietaire)
              }
              onClick={handleSubmit(onProprietaireSubmit)}
            >
              {currentItem ? 'Éditer' : 'Créer'}
            </Button>
          </Toolbar>
        </AppBar>
        <form>
          <DialogTitle>{"Ajout d'un propriétaire"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  disabled={isEdition}
                  color='primary'
                  value={mode}
                  exclusive
                  onChange={(_, newMode) => setMode(newMode)}
                >
                  <ToggleButton value={NEW_CONTACT_MODE}>
                    Nouveau contact
                  </ToggleButton>
                  <ToggleButton value={EXISTING_CONTACT_MODE}>
                    Contact existant
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {mode === EXISTING_CONTACT_MODE && (
                <Grid item xs={12} md={5}>
                  <ContactAutocompleteSearchField
                    label='Contact'
                    disabled={isEdition}
                    contact={contact}
                    setContact={setContact}
                    customFilter={(item) =>
                      item.id !== proprietaire?.contact?.id &&
                      ![
                        ...proprietairesList.map((value) => value?.contact?.id),
                      ].includes(item.id)
                    }
                    required
                  />
                </Grid>
              )}
            </Grid>
            {(mode === NEW_CONTACT_MODE ||
              (mode === EXISTING_CONTACT_MODE && proprietaire)) && (
              <>
                <Grid container spacing={2} alignItems='center' mt={1}>
                  <Grid item xs={12}>
                    <CiviliteRadioGroupField
                      control={control}
                      name='contact.civilite'
                      label='Civilité'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <UpperCaseTextField
                      control={control}
                      name='contact.nom'
                      label='Nom'
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <BaseTextField
                      control={control}
                      name='contact.prenom'
                      label='Prénom'
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <DatePickerField
                      control={control}
                      name='contact.date_naissance'
                      label='Date de naissance'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <EntrepriseField
                      control={control}
                      fieldName='contact.entreprises_list'
                      entreprises={entreprises}
                      defaultValue={proprietaire?.contact.entreprises_list}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <BaseTextField
                      control={control}
                      name='contact.profession'
                      label='Profession'
                      maxLength={256}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems='center' mt={1}>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contact.telephone_fixe'
                      label='Téléphone fixe'
                      maxLength={16}
                      required={
                        requiredMapper.telephone_fixe &&
                        'Téléphone fixe requis.'
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contact.telephone_portable'
                      label='Téléphone portable'
                      maxLength={16}
                      required={
                        requiredMapper.telephone_portable &&
                        'Téléphone portable requis.'
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <MailTextField
                      control={control}
                      name='contact.email'
                      required={requiredMapper.email && 'E-mail requis.'}
                      label='E-mail'
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <AdresseField
                      control={control}
                      fieldName='contact.adresse'
                      relatedFiedlNames={{
                        commune: 'contact.commune',
                        codePostal: 'contact.code_postal',
                        pays: 'contact.pays',
                      }}
                      required={requiredMapper.adresse}
                      label='Adresse'
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contact.commune'
                      maxLength={256}
                      required={requiredMapper.commune && 'Commune requise.'}
                      label='Commune'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CodePostalTextField
                      control={control}
                      name='contact.code_postal'
                      required={
                        requiredMapper.codePostal && 'Code postal requis.'
                      }
                      label='Code postal'
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems='center' mt={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DepartementField
                      label='Département'
                      departements={choicesContact?.departements}
                      control={control}
                      departementFieldName='contact.departement'
                      codePostalFieldName='contact.code_postal'
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AutoCompleteTextField
                      control={control}
                      name='contact.pays'
                      label='Pays'
                      options={choicesContact?.pays}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems='center' mt={1}>
                  <Grid item xs={12} sm={6} md={5}>
                    <SelectField
                      control={control}
                      name='contact.contact_neosylva'
                      required='Contact privilégié Néosylva requis.'
                      label='Contact privilégié Néosylva'
                      baseId='neosylva'
                      renderValue={(value) =>
                        `${value.first_name} ${value.last_name}`
                      }
                      values={
                        usersList &&
                        usersList.filter((user) =>
                          ['neosylva', 'neosylva_cmf'].includes(user.role.name)
                        )
                      }
                      itemMapper={(user) => ({
                        key: user.username,
                        label: `${user.first_name} ${user.last_name}`,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <SelectField
                      control={control}
                      name='personnalite_juridique'
                      label='Personnalité juridique'
                      renderValue={(value) => value.nom}
                      values={choicesProprietaire?.personnalites_juridiques}
                      itemMapper={(personnalite_juridique) => ({
                        key: personnalite_juridique.code,
                        label: personnalite_juridique.nom,
                      })}
                      baseId='personnalite-juridique'
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems='center' mt={1}>
                  {personnaliteJuridiqueWatch?.code === 'PM' && (
                    <>
                      <Grid item xs={12} sm={6} md={2}>
                        <BaseTextField
                          control={control}
                          name='raison_sociale'
                          maxLength={256}
                          label='Raison sociale'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <BaseTextField
                          control={control}
                          name='siret'
                          fixedLength={{
                            value: 14,
                            message: 'Le SIRET doit avoir 14 caractères.',
                          }}
                          label='SIRET'
                          pattern={{
                            value: /[0-9]{14}/i,
                            message: 'Le SIRET doit avoir 14 caractères.',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <AdresseField
                          control={control}
                          fieldName={'adresse_siege'}
                          relatedFiedlNames={{
                            commune: 'commune_siege',
                            codePostal: 'code_postal_siege',
                          }}
                          label={'Adresse siège'}
                          setValue={setValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <CodePostalTextField
                          control={control}
                          name='code_postal_siege'
                          label='Code postal siège'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <BaseTextField
                          control={control}
                          name='commune_siege'
                          maxLength={256}
                          label='Commune siège'
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </DialogContent>
        </form>
      </>
    );
  };

  ProprietaireForm.propTypes = {
    currentItem: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <ProprietaireForm currentItem={editItem} />
    </Dialog>
  );
}

ProprietaireFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  proprietairesList: PropTypes.array,
};
