import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import DiagnosticGestionnaireForm from '@/features/diagnostic-gestionnaire/DiagnosticGestionnaireForm';

import Container from '@mui/material/Container';

export default function DiagnosticGestionnaire() {

  const [searchParams] = useSearchParams();

  const contratId = useMemo(() => searchParams.get('contrat_id'));
  return (
    <Container component='main' maxWidth={false}>
      <DiagnosticGestionnaireForm contratId={contratId} />
    </Container>
  );
}
