import React, { useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';

import { setMessage } from '@/slices/messageSlice';

import {
  fetchUgList,
  fetchUgLink,
  setSyncError,
  clearUgLink,
} from '@/slices/sylvalibreSlice';
import AddUgLinkFormDialog from './AddUgLinkFormDialog';
import RemoveUgLink from './RemoveUgLink';
import SyncUg from './SyncUg';
import { fromISODateTime } from '@/utils/date';
import useOnUnmount from '@/hooks/useOnUnmount';

export default function SylvalibreLink({ uniteGestion, ugLinkIsAvailable }) {
  const dispatch = useDispatch();

  const ugList = useSelector(
    (state) => state.itemReducer.filteredItems.unites_gestion
  );

  const ugIsLinked = useMemo(() => {
    return (
      ugList?.some(
        (ug) => ug.id === uniteGestion?.id && ug?.sylvalibre_ug_uuid
      ) || false
    );
  }, [ugList, uniteGestion]);

  const ugLinkInfos = useSelector((state) => state.sylvalibreReducer.ugLink);

  const isLoading = useSelector((state) => state.isLoadingReducer.isLoading);

  const syncError = useSelector((state) => state.sylvalibreReducer.syncError);

  useEffect(() => {
    if (ugIsLinked && !ugLinkInfos) {
      dispatch(
        fetchUgLink({
          proprieteId: uniteGestion?.propriete?.id,
          uniteGestionId: uniteGestion?.id,
        })
      );
      dispatch(setSyncError(null));
    }
  }, [ugIsLinked, ugLinkInfos, uniteGestion]);

  const onSylvalibreUgSelectClick = () => {
    if (!uniteGestion?.id) {
      dispatch(setMessage("Veuillez d'abord sauvegarder l'unité de gestion avant de sélectionner une UG Sylvalibre."));
      return;
    }

    dispatch(fetchUgList(uniteGestion?.propriete?.id));
  };

  useOnUnmount(() => {
    dispatch(clearUgLink());
    dispatch(setSyncError(null));
  });

  const latestSyncDatetime = fromISODateTime(
    ugLinkInfos?.latest_sync_datetime,
    true
  );

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 0, mb: 1 }} alignItems='center'>
        <Grid item xs='auto'>
          <Typography variant='subtitle2' component='div'>
            Synchronisation Sylvalibre
          </Typography>
        </Grid>
        {ugIsLinked ? (
          <>
            <Grid item xs='auto'>
              <Button variant='contained' fullWidth color='secondary'>
                {ugLinkInfos?.name}
              </Button>
            </Grid>
            <Grid item xs='auto'>
              <RemoveUgLink uniteGestion={uniteGestion} />
            </Grid>
            <Grid item xs='auto'>
              <SyncUg uniteGestion={uniteGestion} />
              {syncError ? (
                <Box
                  mt={1}
                  p={1}
                  border={1}
                  borderColor='error.main'
                  borderRadius={1}
                  color='error.main'
                  display='flex'
                  alignItems='center'
                  maxWidth={500}
                >
                  <ErrorIcon />
                  <Typography ml={1}>{syncError}</Typography>
                </Box>
              ) : (
                <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
                  {ugLinkInfos?.latest_sync_datetime
                    ? `Synchronisée ${latestSyncDatetime}`
                    : 'Non synchronisée'}
                </Typography>
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs='auto'>
            <Button
              onClick={onSylvalibreUgSelectClick}
              fullWidth
              disabled={!ugLinkIsAvailable || isLoading}
              variant='contained'
              color='secondary'
            >
              Sélection UG
            </Button>
          </Grid>
        )}
      </Grid>
      <AddUgLinkFormDialog uniteGestion={uniteGestion} />
    </>
  );
}

SylvalibreLink.propTypes = {
  uniteGestion: PropTypes.object,
  ugLinkIsAvailable: PropTypes.bool.isRequired,
};
