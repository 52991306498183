import React from 'react';

import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';

import useOnMount from '@/hooks/useOnMount';

import DocumentUploadField from '@/components/controlled-fields/DocumentUploadField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function DocumentFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
  userId,
}) {

  const handleClose = () => {
    setOpen(false);
  };

  const DocumentForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      setValue,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: {
        ...item,
        local_url: null,
        is_sharepoint: false,
      },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    const watchIsSharepoint = useWatch({
      control,
      name: 'is_sharepoint',
    });

    useOnMount(() => {
      if (editItem) {
        setValue('is_sharepoint', !editItem.filename);
      }
    });

    const onFormSubmit = () => {
      !editItem && setValue('user_id', userId);
      handleSubmit(onSubmit)();
    };

    return (
      <form>
        <DialogTitle>{item ? 'Éditer' : 'Ajouter'} un document</DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <BaseTextField
                control={control}
                name='name'
                maxLength={256}
                required='Nom requis.'
                label='Nom'
              />
            </Grid>
            <Grid item xs={12}>
              <MultilineTextField
                control={control}
                name='comment'
                label='Commentaire'
              />
            </Grid>
            {!editItem && (
              <Grid item xs={12}>
                <SwitchField
                  control={control}
                  name='is_sharepoint'
                  label='Partager un lien Sharepoint'
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {!watchIsSharepoint ? (
                <DocumentUploadField
                  control={control}
                  setValue={setValue}
                  isRequired
                />
              ) : (
                <BaseTextField
                  control={control}
                  name='repository'
                  maxLength={2048}
                  required='Lien Sharepoint requis.'
                  label='Lien Sharepoint'
                  pattern={{
                    message: 'Lien Sharepoint invalide.',
                    value: /^(http|https):\/\/neosylvafrance\.sharepoint\.com/i,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={onFormSubmit}
          >
            {item ? 'Éditer' : 'Ajouter'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  DocumentForm.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DocumentForm item={editItem} />
    </Dialog>
  );
}

DocumentFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};
