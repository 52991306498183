import React from 'react';

import PropTypes from 'prop-types';

import { useWatch } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import SwitchField from '@/components/controlled-fields/SwitchField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';
import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';

import DocumentTable from '@/features/shared/document/DocumentTable';

import useIsSylvalibreSynced from '@/hooks/useIsSylvalibreSynced';

export default function ProjetNeosylvaTab({
  tabValue,
  propriete,
  documentsList,
  contratProprietaire,
  form,
  choices,
}) {
  const { control } = form;

  const isSylvalibreSynced = useIsSylvalibreSynced(
    contratProprietaire?.unites_gestion_list
  );

  const eligibilitePresumeeAidePublique = useWatch({
    control,
    name: 'contrat_proprietaire.eligibilite_presumee_aide_publique',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={6}>
      <form>
        {propriete && (
          <>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <AreaTextField
                  control={control}
                  name='contrat_proprietaire.surface_pressentie_sous_contrat'
                  label='Surface pressentie sous contrat (ha)'
                  disabled={isSylvalibreSynced}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={12} md={8}>
                <MultilineTextField
                  control={control}
                  name='contrat_proprietaire.itineraires_sylvicoles_pressentis'
                  label='État actuel et itinéraire(s) sylvicole(s) pressenti(s)'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={12} md={8}>
                <MultilineTextField
                  control={control}
                  name='contrat_proprietaire.points_vigilance'
                  label='Éléments pouvant conditionner la faisabilité du projet'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={6} md={4}>
                <SwitchField
                  control={control}
                  name='contrat_proprietaire.eligibilite_presumee_lbc'
                  label='Éligibilité présumée au LBC'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <SwitchField
                  control={control}
                  name='contrat_proprietaire.eligibilite_presumee_aide_publique'
                  label='Éligibilité présumée à une aide publique'
                />
              </Grid>
              {eligibilitePresumeeAidePublique && (
                <Grid item xs={12} sm={6} md={4}>
                  <CheckboxSelectField
                    control={control}
                    name='contrat_proprietaire.detail_aide_publique_list'
                    label='Détail aide publique'
                    values={choices?.aides_publiques}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} alignItems='center' sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <DocumentTable
                  items={documentsList}
                  title={'Documents'}
                  contratProprietaire={contratProprietaire}
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </TabPanelForm>
  );
}

ProjetNeosylvaTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  propriete: PropTypes.object,
  documentsList: PropTypes.array,
  contratProprietaire: PropTypes.object,
  form: PropTypes.object.isRequired,
  choices: PropTypes.object,
};
