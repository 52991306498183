import { useEffect } from 'react';

/**
 * A hook that executes a callback function when the component mounts.
 *
 * This hook captures the callback at the time of the first render and executes it
 * immediately after the component has been mounted to the DOM.
 *
 * @param {Function} callback - The function to be executed on component mount
 *
 * @example
 * // Basic usage
 * useOnMount(() => {
 *   console.log('Component mounted');
 * });
 *
 * @example
 * // If you need to use changing values, capture them explicitly
 * useOnMount(() => {
 *   const currentId = id; // Capture current value
 *   console.log(`Component with ID ${currentId} mounted`);
 * });
 *
 * @note
 * The callback function captures variables as they exist during the first render.
 * If you need to access the latest values of changing variables, use a standard
 * useEffect with the appropriate dependencies instead.
 */
export default function useOnMount(callback) {
  useEffect(() => {
    // Store the callback function at the time the effect is created (first render)
    const mountCallback = callback;

    // Execute the callback immediately after component mount
    mountCallback();
  }, []);
  // Empty dependency array ensures this effect runs only once on mount
  // We intentionally omit 'callback' from dependencies to ensure the effect
  // is not re-executed on every render
}
