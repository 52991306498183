export function naturalSort(a, b) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return collator.compare(a, b);
}


/**
 * Custom comparator to handle ordering between array of string, with variable length.
 * If arrays have different length, the longest array is ordered first.
 * If arrays have the same length, they are ordered alphabetically.
 * @param {Array<String>} a
 * @param {Array<String>} b
 * @return {Boolean} 'True' if a is greater than b, 'False' otherwise
 */
export const createArrayComparator = (mapper) => (a, b) => {
  a = a.map(mapper);
  b = b.map(mapper);

  if (a.length === b.length) {
    return a.join('').toLowerCase() > b.join('').toLowerCase() ? 1 : -1;
  }

  return a.length < b.length ? 1 : -1;
};