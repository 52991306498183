import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';

import { setMessage } from '@/slices/messageSlice';
import { setIsLoading } from '@/slices/isLoadingSlice';

const FicheProprieteFormHeader = ({
  proprieteNom,
  proprieteId,
  onSubmit,
  isValid,
  isFetching,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { open } = useConfirmationDialog();

  const redirectToTableauDeBordCMF = async () => {
    const result = await open({
      content: 'Voulez-vous sauvegarder avant de quitter la fiche propriété ?',
      confirmButton: 'Oui',
      alternativeButton: 'Non',
    });

    if (result === 'confirm') {
      dispatch(setIsLoading(true));
      if (!isValid || isFetching) {
        dispatch(
          setMessage(
            'Sauvegarde impossible, veuillez remplir les champs obligatoires de la fiche propriété.'
          )
        );
        dispatch(setIsLoading(false));
        return;
      }
      onSubmit(false)
        .then(() => {
          navigate('/tableau-bord-cmf');
        })
        .finally(() => dispatch(setIsLoading(false)));
    } else if (result === 'alternative') {
      navigate('/tableau-bord-cmf');
    }
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link
          underline='hover'
          color='inherit'
          onClick={redirectToTableauDeBordCMF}
        >
          Tableau de bord CMF
        </Link>
        {proprieteId && (
          <Typography sx={{ color: 'text.primary' }}>
            Propriété {proprieteNom}
          </Typography>
        )}
      </Breadcrumbs>
      <Typography mb={2} variant='h5' component='div'>
        {proprieteId ? 'Édition' : 'Création'} fiche propriété
      </Typography>
    </>
  );
};

FicheProprieteFormHeader.propTypes = {
  proprieteNom: PropTypes.string,
  proprieteId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default memo(FicheProprieteFormHeader);
