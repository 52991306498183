import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutUser } from '@/slices/authSlice';

import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AppBar from '@mui/material/AppBar';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import LeftDrawer from './LeftDrawer';

export default function Navbar() {
  const isLoggedIn = useSelector(
    (store) => store.persistAuthReducer.isLoggedIn
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const user = useSelector((store) => store.persistAuthReducer.user);
  const isLoading = useSelector((store) => store.isLoadingReducer.isLoading);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserSettings = () => {
    navigate(`/account/${user?.id}`);
    handleClose();
  };

  const handleLogout = () => {
    dispatch(logoutUser('Déconnecté !')).then(({ payload }) => {
      if (payload) {
        navigate('/login');
      }
    });
    handleClose();
  };

  const handleTypoClick = () => {
    navigate('/');
  };

  return (
    <AppBar position='sticky' sx={{ zIndex: 1200, top: 0 }}>
      <Toolbar>
        {isLoggedIn && <LeftDrawer />}
        <Typography
          onClick={handleTypoClick}
          variant='h6'
          component='div'
          sx={{ flexGrow: 1 }}
        >
          Néosylva
        </Typography>
        {isLoggedIn && (
          <div>
            <Button
              onClick={handleMenu}
              color='inherit'
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              startIcon={<AccountCircle />}
            >
              {user?.username}
            </Button>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleUserSettings}>
                <ListItemIcon>
                  <ManageAccountsIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Paramètres du compte</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Se déconnecter</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
      {isLoading && <LinearProgress color='secondary' />}
    </AppBar>
  );
}
