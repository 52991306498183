import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DividerTitle from '@/components/DividerTitle';
import ManyItemsCell from '@/components/ManyItemsCell';
import TabPanelForm from '@/components/TabPanelForm';
import BaseDataGrid from '@/components/BaseDataGrid';

import SylvalibreLinkCell from '@/features/shared/unite-gestion/SylvalibreLinkCell';

/**
 * Center a datagrid cell.
 * Required for proper layout when the row heigh is in 'auto' mode.
 * @param {*} content The cell content
 */
const CENTER_CELL = (content) => (
  <Box
    sx={{
      padding: '5px',
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      whiteSpace: 'pre-wrap',
    }}
  >
    {content}
  </Box>
);

const MAX_COLUMN = 100;

export default function UniteGestionInterventionsTab({
  tabValue,
  unitesGestionList,
}) {

  const columnsUnitesGestions = useMemo(
    () => [
      {
        field: 'nom',
        headerName: 'Nom',
        cellClassName: 'datagrid-cell',
        width: 100,
        editable: false,
      },
      {
        field: 'surface_sig_ug',
        headerName: 'Surface unité de gestion',
        width: 180,
        editable: false,
      },
      {
        field: 'itineraire_sylvicole_preconise',
        headerName: 'Itinéraire sylvicole préconisé',
        valueGetter: (value) => value?.nom,
        width: 250,
        editable: false,
      },
      {
        field: 'peuplement_initial',
        headerName: 'Peuplement existant',
        valueGetter: (value) => value?.type.nom,
        width: 210,
        editable: false,
      },
      {
        field: 'essences_principales_existantes',
        headerName: 'Essences principales existantes',
        width: 220,
        editable: false,
        valueGetter: (value) =>
          value?.essences_list
            .filter((value) => value.type === 'Principale')
            .map((value) => value?.essence.nom_vernaculaire),
        renderCell: ({ row }) => (
          <ManyItemsCell
            itemId={row.id}
            relatedItems={{
              relatedItemsList:
                row.peuplement_initial?.essences_list.filter(
                  (value) => value.type === 'Principale'
                ) || [],
              getRelatedName: (value) => value?.essence.nom_vernaculaire,
              relatedVerboseName: 'essence',
            }}
          />
        ),
      },
      {
        field: 'peuplement_objectif',
        headerName: 'Peuplement objectif',
        valueGetter: (value) => value?.nom,
        width: 150,
        editable: false,
      },
      {
        field: 'essences_principales_objectives',
        headerName: 'Essences principales objectives',
        width: 220,
        editable: false,
        valueGetter: (_, row) =>
          row.essences_list
            .filter((essence) => essence.type === 'Principale')
            .map((essence) => essence?.nom_vernaculaire),
        renderCell: ({ row }) => (
          <ManyItemsCell
            itemId={row.id}
            relatedItems={{
              relatedItemsList: row.essences_list?.filter(
                (essence) => essence.type === 'Principale'
              ),
              getRelatedName: (essence_parcelle) =>
                essence_parcelle?.essence.nom_vernaculaire,
              relatedVerboseName: 'essence',
            }}
          />
        ),
      },
      {
        field: 'station',
        headerName: 'Station',
        valueGetter: (value) => value?.numero,
        width: 100,
        editable: false,
      },
      {
        field: 'sylvalibre_ug_uuid',
        headerName: 'Lien sylvalibre',
        type: 'singleSelect',
        valueGetter: (value) => (value ? 'Lien établi' : 'Lien à établir'),
        renderCell: ({ row }) => <SylvalibreLinkCell row={row} />,
        valueOptions: ['Lien établi', 'Lien à établir'],
        width: 150,
        editable: false,
      },
      {
        field: 'contrat_proprietaire',
        headerName: 'Contrat',
        valueGetter: (value) => value.code_chantier,
        width: 300,
        editable: false,
      },
    ],
    []
  );

  /**
   * Create and memoize a mapping that associate a UG with a list all all its associated interventions, filtered by year
   * The strucutre of the object : UG -> Years -> Interventions
   */
  const mapping = useMemo(() => {
    const mapping = {};
    unitesGestionList?.forEach((unite) => {
      const interventions = {};

      // Iterate over all interventions of the UG
      unite.interventions_list.forEach((intervention) => {
        const annee = intervention.annee_prevue;
        const type = intervention.type.nom;

        /**
         * If the intervention year is already registered in the mapper, add the intervention to the mapper list.
         * Otherwise, register the intervention year and add the intervention to the new mapper list.
         */
        interventions[annee]?.push(type) || (interventions[annee] = [type]);
      });

      // Associate the UG to the created mapper object
      mapping[unite.nom] = interventions;
    });
    return mapping;
  }, [unitesGestionList]);

  /**
   * Memoize computed columns & groups for interventions datagrid
   */
  const { columnsInterventions, groupColumnsInterventions } = useMemo(() => {
    // Base columns
    const columnsInterventions = [
      {
        field: 'nom',
        headerName: "Nom de l'UG",
        width: 100,
        editable: false,
        renderCell: ({ row }) => CENTER_CELL(row?.nom),
        cellClassName: 'datagrid-cell',
        headerAlign: 'center',
      },
      {
        field: 'peuplement_objectif',
        headerName: 'Peuplement objectif',
        width: 150,
        editable: false,
        renderCell: ({ row }) => CENTER_CELL(row.peuplement_objectif?.nom),
        headerAlign: 'center',
      },
      {
        field: 'surface_sig_ug',
        headerName: 'Surface (ha)',
        width: 100,
        editable: false,
        renderCell: ({ row }) => CENTER_CELL(row?.surface_sig_ug),
        headerAlign: 'center',
      },
    ];

    // Base group
    const groupColumnsInterventions = [
      {
        groupId: 'title',
        headerName: 'Année',
        children: [{ field: 'surface_sig_ug' }],
        headerClassName: 'datagrid-header',
      },
    ];

    /**
     * Get the starting year of the columns by getting the lowest year registered in the mapper.
     * If the mapper contains no data (i.e there are no intervention scheduled), then the current year is selected.
     */
    const yearsFlatList = Object.values(mapping).map(Object.keys).flat();
    const startingYear = yearsFlatList.length
      ? Math.min(...yearsFlatList)
      : new Date().getFullYear();
    const endingYear = Math.min(
      yearsFlatList.length
        ? Math.max(...yearsFlatList)
        : new Date().getFullYear(),
      startingYear + MAX_COLUMN
    );

    /**
     * Generate the columns, from the starting year to the ending year (one column is generated if there are no interventions).
     * Also generate a unique group for each created column, representing the year number.
     */
    for (let i = 0; i < endingYear - startingYear + 1; i++) {
      const columnYear = startingYear + i;
      columnsInterventions.push({
        field: `year_${i}`,
        renderCell: ({ row }) => {
          const ug = mapping[row?.nom];
          const interventions = ug?.[columnYear];
          return CENTER_CELL(
            interventions?.map((intervention) => `‣ ${intervention}\n`)
          );
        },
        headerName: `${columnYear}`,
        width: 220,
        editable: false,
        headerAlign: 'center',
      });
      groupColumnsInterventions.push({
        groupId: `year_${i}`,
        headerName: `${i + 1}`,
        children: [{ field: `year_${i}` }],
        headerAlign: 'center',
        headerClassName: 'datagrid-header',
      });
    }

    return { columnsInterventions, groupColumnsInterventions };
  }, [unitesGestionList]);

  return (
    <TabPanelForm currentValue={tabValue} index={5}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <DividerTitle text='Synthèse des unités de gestion dans nos contrats' />
          </Grid>
          <Grid item xs={12}>
            <BaseDataGrid
              rows={unitesGestionList}
              columns={columnsUnitesGestions}
              sx={{ mb: 1 }}
              alternateRowColor
              headerClassName='datagrid-header'
            />
            <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
              Les informations de ce tableau sont modifiables depuis les
              contrats qui les décrivent
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Synthèse des interventions sylvicoles prévues au contrat' />
          </Grid>
          <Grid item xs={12}>
            <BaseDataGrid
              rows={unitesGestionList}
              columns={columnsInterventions}
              columnGroupingModel={groupColumnsInterventions}
              sx={{ mb: 1 }}
              alternateRowColor
              getRowHeight={() => 'auto'}
              headerClassName='datagrid-header'
            />
            <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
              Les informations de ce tableau sont modifiables depuis les
              contrats qui les décrivent
            </Typography>
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

UniteGestionInterventionsTab.propTypes = {
  tabValue: PropTypes.number,
  unitesGestionList: PropTypes.array,
};
