import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

export default function NewProprieteButton() {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate('/fiche-propriete')}
      fullWidth={false}
      variant='contained'
    >
      Créer une nouvelle propriété
    </Button>
  );
}
