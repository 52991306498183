import React from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import NewProprieteButton from './NewProprieteButton';
import EtatAvancementLegende from './EtatAvancementLegende';

export default function ProprietesTabActions({ choices }) {
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <NewProprieteButton />
      <EtatAvancementLegende choices={choices} />
    </Box>
  );
}

ProprietesTabActions.propTypes = {
  choices: PropTypes.object,
};
