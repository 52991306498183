import React from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

export default function StickyBox({ children }) {
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 64,
        width: '100%',
        p: 2,
        backgroundColor: '#fff',
        borderBottom: 1,
        borderColor: 'divider',
        zIndex: 1100,
      }}
    >
      {children}
    </Box>
  );
}

StickyBox.propTypes = {
  children: PropTypes.node.isRequired,
};
