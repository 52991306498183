import React, { useEffect, useMemo, useState } from 'react';

import deepmerge from 'deepmerge';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import useChoices from '@/hooks/useChoices';
import useDownloadDocument from '@/hooks/useDownloadDocument';
import useGoBackHistory from '@/hooks/useGoBackHistory';
import useOnMount from '@/hooks/useOnMount';
import useSetFormValues from '@/hooks/useSetFormValues';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';

import RenseignementProprietaireTab from '@/features/diagnostic-neosylva/RenseignementProprietaireTab';
import SubmitHandlerButton from '@/features/shared/SubmitHandlerButton';

import { setIsLoading } from '@/slices/isLoadingSlice';
import { setFilteredItems } from '@/slices/itemSlice';
import { setMessage } from '@/slices/messageSlice';

import APIService from '@/APIService';
import EnjeuxEconomiquesTab from './EnjeuxEconomiquesTab';
import EnjeuxSocioEnvTab from './EnjeuxSocioEnvTab';
import HistoriqueDeGestionTab from './HistoriqueDeGestionTab';
import ProjetNeosylvaTab from './ProjetNeosylvaTab';
import RenseignementProprieteTab from './RenseignementProprieteTab';
import SituationAdministrativeTab from './SituationAdministrativeTab';

import { createFileFromUrl } from '@/utils/file';

import StickyBox from '@/components/StickyBox';

export default function DiagnosticNeosylvaForm({ edition = false, contratId }) {
  const goBackHistory = useGoBackHistory();
  const dispatch = useDispatch();
  const choices = useChoices('diagnostic_neosylva');
  const fetchFilteredItems = useFetchFilteredItems();

  const isLoading = useSelector((store) => store.isLoadingReducer.isLoading);
  const isFetching = useSelector(
    (store) => store.itemReducer.pendingActions !== 0
  );

  const parcellesList = useSelector(
    (store) =>
      store.itemReducer.filteredItems
        .parcelles_cadastrales_pressenties_sous_contrat
  );
  const documentsList = useSelector(
    (store) => store.itemReducer.filteredItems.documents
  );

  const proprietairesList = useSelector(
    (store) => store.itemReducer.filteredItems.proprietaires
  );

  const [propriete, setPropriete] = useState(null);
  const [contratProprietaire, setContratProprietaire] = useState(null);
  const [gestionnaire, setGestionnaire] = useState(null);

  useOnMount(() => {
    if (!contratId) {
      dispatch(setFilteredItems({ itemsList: [], itemType: 'proprietaires' }));
      return;
    }

    APIService.get({
      url: `contrats_proprietaires/${contratId}`,
    }).then(({ data }) => setContratProprietaire(data));
  });

  const form1 = useForm({
    defaultValues: {
      contrat_proprietaire: {
        referent_technique: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue1,
    getValues: getValues1,
    formState: { isValid: isValid1 },
  } = form1;

  const form2 = useForm({
    defaultValues: {
      different_address_propriete: false,
      propriete: {
        nom_commun: null,
        adresse: null,
        commune: null,
        code_postal: null,
        departement: null,
        surface_totale_propriete: null,
        cmf_neosylva: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    control: control2,
    setValue: setValue2,
    getValues: getValues2,
    formState: { isValid: isValid2 },
  } = form2;

  const form3 = useForm({
    defaultValues: {
      propriete: {
        certification_forestiere: null,
        existence_dgd: false,
        gestionnaire_preexistant: false,
        droits_usage_servitudes_divers: null,
        adhesion_syndicat: false,
        adhesion_syndicat_detail: null,
      },
      contrat_proprietaire: {
        gestionnaire: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue3,
    getValues: getValues3,
    formState: { isValid: isValid3 },
  } = form3;

  const form4 = useForm({
    defaultValues: {
      propriete: {
        besoin_acces_supplementaire: null,
        commentaire_chasse_pression_gibier: null,
        identite_detenteur_droit_chasse: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue4,
    getValues: getValues4,
    formState: { isValid: isValid4 },
  } = form4;

  const form5 = useForm({
    defaultValues: {
      propriete: {
        frequentation_public: null,
        importance_frequentation: null,
        zonages_identifies_list: [],
        zonages_identifies_commentaire: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue5,
    getValues: getValues5,
    formState: { isValid: isValid5 },
  } = form5;

  const form6 = useForm({
    defaultValues: {
      propriete: {
        historique_gestion: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue6,
    getValues: getValues6,
    formState: { isValid: isValid6 },
  } = form6;

  const form7 = useForm({
    defaultValues: {
      contrat_proprietaire: {
        surface_pressentie_sous_contrat: null,
        eligibilite_presumee_lbc: false,
        eligibilite_presumee_aide_publique: false,
        detail_aide_publique_list: [],
        points_vigilance: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue7,
    getValues: getValues7,
    formState: { isValid: isValid7 },
  } = form7;

  const proprieteFields = [
    ['nom_commun', true, setValue2],
    ['adresse', true, setValue2],
    ['commune', true, setValue2],
    ['code_postal', true, setValue2],
    ['departement', true, setValue2],
    ['surface_totale_propriete', false, setValue2],
    ['cmf_neosylva', true, setValue2],
    ['existence_dgd', true, setValue3],
    ['certification_forestiere', false, setValue3],
    ['gestionnaire_preexistant', false, setValue3],
    ['droits_usage_servitudes_divers', false, setValue3],
    ['besoin_acces_supplementaire', false, setValue4],
    ['commentaire_chasse_pression_gibier', false, setValue4],
    ['identite_detenteur_droit_chasse', false, setValue4],
    ['frequentation_public', false, setValue5],
    ['importance_frequentation', false, setValue5],
    ['zonages_identifies_list', false, setValue5],
    ['zonages_identifies_commentaire', false, setValue5],
    ['historique_gestion', false, setValue6],
    ['adhesion_syndicat', false, setValue3],
    ['adhesion_syndicat_detail', false, setValue3],
    ['gestionnaire', false, setValue3],
  ];

  const setFormValues = useSetFormValues();

  const differentAddress = useWatch({
    control: control2,
    name: 'different_address_propriete',
  });

  const downloadDocument = useDownloadDocument();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (contratProprietaire?.id) {
      fetchFilteredItems({
        filter: {
          item_type: 'contrat_proprietaire',
          item_id: contratProprietaire?.id,
        },
        itemTypes: ['proprietaires'],
      });
    }
  }, [contratProprietaire]);

  const ActionBtn = () => {
    const isLastTab = tabValue === 6;

    const disabledSubmit =
      !isValid1 ||
      !isValid2 ||
      !isValid3 ||
      !isValid4 ||
      !isValid5 ||
      !isValid6 ||
      !isValid7 ||
      !proprietairesList?.length ||
      !propriete ||
      isFetching;

    const onSubmit = async (generateDocument) => {
      const updateDiagnostic = edition || contratProprietaire?.id;

      const formValuesList = [
        getValues1(),
        getValues2(),
        getValues3(),
        getValues4(),
        getValues5(),
        getValues6(),
        getValues7(),
      ];

      const formValues = deepmerge.all(formValuesList);

      if (updateDiagnostic) {
        formValues.contrat_proprietaire.id = contratProprietaire.id;
        formValues.propriete.gestionnaire = gestionnaire;
      }
      formValues.propriete.id = propriete.id;
      formValues.parcelles_cadastrales_pressenties_sous_contrat_list =
        parcellesList;



      if (!formValues.contrat_proprietaire.eligibilite_presumee_aide_publique) {
        formValues.contrat_proprietaire.detail_aide_publique_list = [];
      }

      if (!formValues.propriete.frequentation_public) {
        formValues.propriete.importance_frequentation = null;
      }

      if (!formValues.propriete.adhesion_syndicat) {
        formValues.propriete.adhesion_syndicat_detail = null;
      }

      const action = updateDiagnostic ? 'edit' : 'create';

      const formData = new FormData();
      const filesList = [];
      const sharepointsList = [];
      documentsList?.forEach((document) =>
        (document.repository ? sharepointsList : filesList).push(document)
      );
      formValues.sharepoints_list = sharepointsList;
      formValues.files_list = filesList;
      formValues.proprietaires_list = proprietairesList;

      formData.append('body', JSON.stringify(formValues));

      for (const file of filesList) {
        if (file.local_url && file.temp_uuid) {
          formData.append(
            'files',
            await createFileFromUrl(file.local_url, file.temp_uuid)
          );
        }
      }

      const response = await APIService.post({
        url: `diagnostic_neosylva/${action}`,
        data: formData,
        onError: (res) => {
          const message = res.data.message || res.data.detail;
          if (message) {
            dispatch(setMessage(message));
          }
        },
      }).finally(() => !generateDocument && dispatch(setIsLoading(false)));

      if (response.data) {
        const { data } = response;

        fetchFilteredItems({
          filter: { propriete_id: propriete.id },
          itemTypes: ['parcelles_cadastrales_pressenties_sous_contrat'],
        });

        if (contratId) {
          fetchFilteredItems({
            filter: { item_type: 'contrat_proprietaire', item_id: contratId },
            itemTypes: ['proprietaires'],
          });
        }

        if (!updateDiagnostic) {
          setContratProprietaire({
            ...formValues.contrat_proprietaire,
            id: data.contrat_id,
          });
        }
        dispatch(setMessage(data.message));

        if (generateDocument) {
          APIService.post({
            url: `diagnostic_neosylva/download/${data.contrat_id}`,
            opts: {
              responseType: 'blob',
            },
            onError: (res) => {
              const message = res.data.message || res.data.detail;
              if (message) {
                dispatch(setMessage(message));
              }
            },
          })
            .then(downloadDocument)
            .finally(() => dispatch(setIsLoading(false)));
        }
      }
    };

    let disableNext;

    switch (tabValue) {
      case 0:
        disableNext = !isValid1 || !proprietairesList?.length;
        break;
      case 1:
        disableNext = !isValid2 || !propriete;
        break;
      case 2:
        disableNext = !isValid3;
        break;
      case 3:
        disableNext = !isValid4;
        break;
      case 4:
        disableNext = !isValid5;
        break;
      case 5:
        disableNext = !isValid6;
        break;
    }

    return (
      <Grid container sx={{ mt: 3, justifyContent: 'space-between' }}>
        <Grid item>
          <Button variant='outlined' sx={{ mb: 1, mr: 1 }} onClick={onCancel}>
            Fermer
          </Button>
          {!isLastTab && (
            <Button
              variant='contained'
              sx={{ mb: 1 }}
              disabled={disableNext}
              onClick={() => setTabValue(tabValue + 1)}
            >
              Suivant
            </Button>
          )}
        </Grid>
        <Grid item>
          <SubmitHandlerButton
            onSubmit={() => onSubmit(false)}
            label={edition ? 'Sauvegarder' : 'Créer'}
            disabledSubmit={disabledSubmit}
            sx={{ mb: 1, mr: 1 }}
            variant='contained'
          />
          <Button
            onClick={() => {
              dispatch(setIsLoading(true));
              onSubmit(true);
            }}
            disabled={disabledSubmit || isLoading}
            variant='contained'
            sx={{ mb: 1 }}
          >
            {`${edition ? 'Sauvegarder' : 'Créer'} et télécharger`}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const onCancel = () => {
    goBackHistory();
  };

  const fetchPropriete = async (proprieteId) => {
    const proprieteRes = await APIService.get({
      url: `proprietes/${proprieteId}`,
    });
    setPropriete(proprieteRes.data);
  };

  const contratProprietaireFields = [
    ['referent_technique', false, setValue1],
    ['date_premiere_visite_neosylva', false, setValue2],
    ['surface_pressentie_sous_contrat', false, setValue7],
    ['gestionnaire', false, setValue3],
    ['eligibilite_presumee_lbc', false, setValue7],
    ['eligibilite_presumee_aide_publique', false, setValue7],
    ['detail_aide_publique_list', false, setValue7],
    ['itineraires_sylvicoles_pressentis', false, setValue7],
    ['points_vigilance', false, setValue7],
  ];

  useEffect(() => {
    if (!edition || !contratProprietaire) {
      return;
    }

    fetchPropriete(contratProprietaire.propriete.id);
    setGestionnaire(propriete?.gestionnaire);

    dispatch(
      setFilteredItems({
        itemsList: contratProprietaire.sharepoints_list.concat(
          contratProprietaire.files_list
        ),
        itemType: 'documents',
      })
    );

    setFormValues(
      contratProprietaireFields,
      contratProprietaire,
      'contrat_proprietaire'
    );

    return () => {
      setPropriete(null);
    };
  }, [contratProprietaire]);

  const proprietairePrincipale = useMemo(() => {
    if (!proprietairesList) {
      return null;
    }

    if (!contratProprietaire && proprietairesList.length > 0) {
      return proprietairesList[0];
    }

    return proprietairesList.find(
      (proprietaire) =>
        proprietaire.contact.id ===
        contratProprietaire.proprietaire_principal?.id
    );
  }, [contratProprietaire, proprietairesList]);

  return (
    <Box sx={{ width: '100%' }}>
      <StickyBox>
        <Typography mb={2} variant='h5' component='div'>
          {!edition
            ? 'Créer un compte rendu de visite Néosylva'
            : 'Compte rendu de visite Néosylva'}
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label='Renseignements généraux - Propriétaire' />
          <Tab
            label='Renseignements généraux - Propriété'
            disabled={!proprietairesList?.length}
          />
          <Tab label='Situation administrative' disabled={!propriete} />
          <Tab label='Enjeux économiques' disabled={!propriete} />
          <Tab label='Enjeux socio-environnementaux' disabled={!propriete} />
          <Tab label='Historique de gestion' disabled={!propriete} />
          <Tab label='Projet Néosylva' disabled={!propriete} />
        </Tabs>
      </StickyBox>
      <RenseignementProprietaireTab
        edition={edition}
        tabValue={tabValue}
        contratProprietaireState={{
          contratProprietaire,
          setContratProprietaire,
        }}
        contratId={contratId}
        form={form1}
        choices={choices}
        proprietairesList={proprietairesList}
      />
      <RenseignementProprieteTab
        tabValue={tabValue}
        proprietaire={proprietairePrincipale}
        proprieteState={{ propriete, setPropriete }}
        parcellesList={parcellesList}
        contratProprietaire={contratProprietaire}
        proprieteFields={proprieteFields}
        differentAddress={differentAddress}
        contratId={contratId}
        form={form2}
        choices={choices}
      />
      <SituationAdministrativeTab
        tabValue={tabValue}
        edition={edition}
        setGestionnaire={setGestionnaire}
        propriete={propriete}
        form={form3}
      />
      <EnjeuxEconomiquesTab
        tabValue={tabValue}
        propriete={propriete}
        form={form4}
      />
      <EnjeuxSocioEnvTab
        tabValue={tabValue}
        propriete={propriete}
        choices={choices}
        form={form5}
      />
      <HistoriqueDeGestionTab
        tabValue={tabValue}
        propriete={propriete}
        form={form6}
      />
      <ProjetNeosylvaTab
        tabValue={tabValue}
        propriete={propriete}
        documentsList={documentsList}
        contratProprietaire={contratProprietaire}
        choices={choices}
        form={form7}
      />
      <ActionBtn />
    </Box>
  );
}

DiagnosticNeosylvaForm.propTypes = {
  contratId: PropTypes.string,
  edition: PropTypes.bool,
};
