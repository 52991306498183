import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useFormItem from '@/hooks/useFormItem';
import useItems from '@/hooks/useItems';
import useOnMount from '@/hooks/useOnMount';
import useConditionalRequired from '@/hooks/useConditionalRequired';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import DividerTitle from '@/components/DividerTitle';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';

import AdresseField from '@/features/shared/AdresseField';
import EntrepriseField from '@/features/shared/EntrepriseField';

import { setIsLoading } from '@/slices/isLoadingSlice';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function ProprietaireForm({ item }) {
  const itemType = 'proprietaires';

  const fetchFilteredItems = useFetchFilteredItems();

  const usersList = useItems('users');

  const entreprises = useItems('entreprises');

  const choicesProprietaire = useChoices(itemType);
  const choicesContact = useChoices('contacts');

  const isFetching = useSelector(
    (store) => store.itemReducer.pendingActions !== 0
  );

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);
  const dispatch = useDispatch();
  const { open } = useConfirmationDialog();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const conditionalRequiredFields = [
    ['contact.telephone_fixe'],
    ['contact.telephone_portable'],
    ['contact.email'],
  ];

  const requiredMapper = useConditionalRequired(
    conditionalRequiredFields,
    control,
    trigger
  );

  const contratsProprietaireList = useSelector(
    (store) => store.itemReducer.filteredItems.contrats_proprietaires
  );

  /**
   * Récupère les contrats, en lien avec le rôle de Propriétaire (référent technique)
   */
  useOnMount(() => {
    fetchFilteredItems({
      filter: { item_type: 'proprietaire', item_id: item.contact.id },
      itemTypes: ['contrats_proprietaires'],
    });
  });

  const onFormSubmit = async () => {
    if (
      getValues('contact.decede_inactif') &&
      !item.contact.decede_inactif &&
      contratsProprietaireList?.length
    ) {
      let content = 'Liste des contrats rattachés au propriétaire :\n';
      for (const contrat of contratsProprietaireList) {
        content += `• ${contrat.code_chantier}\n`;
      }
      const result = await open({
        title: 'Contact décédé.e / non actif.ve',
        content,
      });

      if (result === 'decline') {
        dispatch(setIsLoading(false));
        return;
      }
    }

    handleSubmit(onSubmit)();
  };

  const [personnaliteJuridique, setPersonnaliteJuridique] = useState(
    item.personnalite_juridique?.code
  );

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        Édition proprietaire
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <DividerTitle text={'Informations générales'} />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'personnalite_juridique'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='personnalite-juridique-select-label'>
                    Personnalité juridique
                  </InputLabel>
                  <Select
                    labelId='personnalite-juridique-select-label'
                    id='personnalite-juridique-select'
                    onChange={(e) => {
                      setPersonnaliteJuridique(e.target.value.code);
                      onChange(e);
                    }}
                    value={value || ''}
                    label='Personnalité juridique'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choicesProprietaire &&
                      choicesProprietaire.personnalites_juridiques.map(
                        (item) => (
                          <MenuItem key={item.code} value={item}>
                            {item.nom}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact.civilite'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>Civilité</FormLabel>
                  <RadioGroup
                    row
                    value={value ? value : ''}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value='Monsieur'
                      control={<Radio />}
                      label='Monsieur'
                    />
                    <FormControlLabel
                      value='Madame'
                      control={<Radio />}
                      label='Madame'
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact.nom'}
              control={control}
              rules={{ maxLength: 256, required: 'Nom requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nom'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onBlur={(e) => onChange(e.target.value.toUpperCase())}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact.prenom'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Prénom'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='contact.date_naissance'
              control={control}
              label='Date de naissance'
            />
          </Grid>
        </Grid>
        {personnaliteJuridique === 'PM' && (
          <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name={'raison_sociale'}
                control={control}
                rules={{
                  maxLength: 256,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Raison sociale'}
                    inputProps={{
                      maxLength: 256,
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name={'siret'}
                control={control}
                rules={{
                  maxLength: {
                    value: 14,
                    message: 'Le SIRET doit avoir 14 caractères.',
                  },
                  minLength: {
                    value: 14,
                    message: 'Le SIRET doit avoir 14 caractères.',
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'SIRET'}
                    inputProps={{
                      maxLength: 14,
                      pattern: '[0-9]*',
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AdresseField
                control={control}
                fieldName={'adresse_siege'}
                relatedFiedlNames={{
                  commune: 'commune_siege',
                  codePostal: 'code_postal_siege',
                }}
                label={'Adresse siège'}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name={'code_postal_siege'}
                control={control}
                rules={{
                  maxLength: {
                    value: 5,
                    message: 'Le code postal doit avoir 5 caractères.',
                  },
                  minLength: {
                    value: 5,
                    message: 'Le code postal doit avoir 5 caractères.',
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Code postal siège'}
                    inputProps={{
                      maxLength: 5,
                      pattern: '[0-9]*',
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name={'commune_siege'}
                control={control}
                rules={{
                  maxLength: 256,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Commune siège'}
                    inputProps={{
                      maxLength: 256,
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <EntrepriseField
              control={control}
              fieldName={'contact.entreprises_list'}
              defaultValue={item.contact.entreprises_list}
              entreprises={entreprises ? entreprises : []}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact.profession'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Profession'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={3} md={2}>
            <BaseTextField
              control={control}
              name='contact.telephone_fixe'
              label='Téléphone fixe'
              maxLength={16}
              required={
                requiredMapper.telephone_fixe && 'Téléphone fixe requis.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <BaseTextField
              control={control}
              name='contact.telephone_portable'
              maxLength={16}
              required={
                requiredMapper.telephone_portable &&
                'Téléphone portable requis.'
              }
              label='Téléphone portable'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MailTextField
              control={control}
              name='contact.email'
              required={requiredMapper.email && 'E-mail requis.'}
              maxLength={120}
              label='E-mail'
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={5} md={5}>
            <AdresseField
              control={control}
              fieldName={'contact.adresse'}
              relatedFiedlNames={{
                commune: 'contact.commune',
                codePostal: 'contact.code_postal',
                pays: 'contact.pays',
              }}
              label={'Adresse'}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Controller
              name={'contact.commune'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Commune'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Controller
              name={'contact.code_postal'}
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
                minLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code postal'}
                  inputProps={{
                    maxLength: 5,
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact.departement'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='dept-select-label'>Département</InputLabel>
                  <Select
                    labelId='dept-select-label'
                    id='dept-select'
                    value={value || ''}
                    label='Département'
                    onChange={onChange}
                    error={!!error}
                  >
                    {choicesContact &&
                      choicesContact.departements.map((val, idx) => (
                        <MenuItem key={idx} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contact.pays'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={!choicesContact}
                  disablePortal
                  defaultValue={item.contact.pays}
                  onChange={(_, val) => onChange(val)}
                  options={choicesContact ? choicesContact.pays : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Pays'
                      error={!!error}
                      value={value ? value : ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <DividerTitle text={'Relation Néosylva'} />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4} md={3}>
            <Controller
              name={'origine_commerciale'}
              control={control}
              rules={{
                required: 'Origine commerciale requise.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required={true}>
                  <InputLabel id='origine-commerciale-select-label'>
                    Origine commerciale
                  </InputLabel>
                  <Select
                    labelId='origine-commerciale-select-label'
                    id='origine-commerciale-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Origine commerciale'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choicesProprietaire &&
                      choicesProprietaire.origines_commerciales_types.map(
                        (item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.nom}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Controller
              name={'contact.contact_neosylva'}
              control={control}
              rules={{
                maxLength: 50,
                required: 'Contact privilégié Néosylva requis.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required={true}>
                  <InputLabel id='neosylva-select-label'>
                    Contact privilégié Néosylva
                  </InputLabel>
                  <Select
                    labelId='neosylva-select-label'
                    id='neosylva-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Contact privilégié Néosylva'
                    error={!!error}
                    defaultValue={null}
                    renderValue={(value) =>
                      `${value.first_name} ${value.last_name}`
                    }
                  >
                    {usersList &&
                      usersList
                        .filter((user) =>
                          ['neosylva', 'neosylva_cmf'].includes(user.role.name)
                        )
                        .map((user) => (
                          <MenuItem key={user.username} value={user}>
                            {user.first_name} {user.last_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'contact.relation_contractuelle_neosylva'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Relation contractuelle avec Néosylva'
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={5} sm={3} md={2}>
            <Controller
              name={'contact.newsletter'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Newsletter'
                />
              )}
            />
          </Grid>
          <Grid item xs={7} sm={4} md={2}>
            <Controller
              name={'contact.rendez_vous_annuel_pressenti'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Rendez-vous annuel pressenti'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Controller
              name={'contact.voeux'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='voeux-select-label'>Vœux</InputLabel>
                  <Select
                    labelId='voeux-select-label'
                    id='voeux-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Vœux'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choicesContact &&
                      choicesContact.voeux_types.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'contact.commentaire'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Commentaire'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={5} md={2}>
            <SwitchField
              name='contact.decede_inactif'
              control={control}
              label='Décédé.e / non actif.ve'
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={onFormSubmit}
          submitLabel='Éditer'
          disabledSubmit={!isDirty || !isValid || isFetching}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

ProprietaireForm.propTypes = {
  item: PropTypes.object.isRequired,
};
