import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import TabPanelForm from '@/components/TabPanelForm';
import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';

export default function SuiviEconomiqueTab({ tabValue, form, choices }) {

  const { control } = form;

  const eligibilitePresumeeAidePublique = useWatch({
    control,
    name: 'contrat_proprietaire.eligibilite_presumee_aide_publique',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={3}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={4}>
            <SwitchField
              control={control}
              name='contrat_proprietaire.eligibilite_presumee_lbc'
              label='Éligibilité présumée au LBC'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SwitchField
              control={control}
              name='contrat_proprietaire.eligibilite_presumee_aide_publique'
              label='Éligibilité présumée à une aide publique'
            />
          </Grid>
          {eligibilitePresumeeAidePublique && (
            <Grid item xs={12} sm={6} md={4}>
              <CheckboxSelectField
                control={control}
                name='contrat_proprietaire.detail_aide_publique_list'
                label='Détail aide publique'
                values={choices?.aides_publiques}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </TabPanelForm>
  );
}

SuiviEconomiqueTab.propTypes = {
  tabValue: PropTypes.number,
  form: PropTypes.object,
  choices: PropTypes.object,
};
