import React, { memo } from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

import useGoBackHistory from '@/hooks/useGoBackHistory';

const FicheProprieteFormFooter = ({ onSubmit, isValid, isFetching }) => {

  const goBackHistory = useGoBackHistory('/tableau-bord-cmf');

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <FormSubmitButtons
        onSubmit={onSubmit}
        submitLabel={'Sauvegarder'}
        onCancel={goBackHistory}
        disabledSubmit={!isValid || isFetching}
      />
    </Box>
  );
};

FicheProprieteFormFooter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default memo(FicheProprieteFormFooter);
