import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { clearItems, fetchAllItems } from '@/slices/itemSlice';

import useOnUnmount from './useOnUnmount';

/**
 * Custom hook to fetch and manage items from the Redux store.
 *
 * This hook automatically fetches items of a specific type if they don't exist in the store,
 * and cleans up the items when the component unmounts to prevent memory leaks.
 *
 * @param {string} itemType - The type of items to fetch (e.g., 'gestionnaires_pro', 'users')
 * @returns {Array|Object|null} - The items data from the Redux store, or null if not yet loaded
 */
export default function useItems(itemType) {
  const dispatch = useDispatch();

  // Get items from Redux store based on itemType
  const items = useSelector((store) => store.itemReducer.items[itemType]);

  // Fetch items if they don't exist in the store
  useEffect(() => {
    if (!items) {
      dispatch(fetchAllItems(itemType));
    }
  }, [items, itemType, dispatch]);

  // Clean up items when component unmounts
  useOnUnmount(() => dispatch(clearItems(itemType)));

  return items;
}
