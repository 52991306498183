import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GridActionsCellItem } from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';
import useChoices from '@/hooks/useChoices';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import StatefulDataGrid from '@/components/StatefulDataGrid';

export default function Users() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'users';

  const choices = useChoices(itemType);

  const itemsList = useItems(itemType);

  const columns = [
    {
      field: 'username',
      headerName: 'Identifiant',
      width: 200,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'role',
      headerName: 'Rôle',
      width: 120,
      editable: false,
      type: 'singleSelect',
      valueOptions: choices?.roles.map((value) => value.verbose_name),
      valueGetter: (value) => value.verbose_name,
    },
    {
      field: 'is_admin',
      headerName: 'Administrateur',
      width: 120,
      editable: false,
      type: 'boolean',
    },
    {
      field: 'last_login_datetime',
      headerName: 'Dernière connexion',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nom)}
        />,
      ],
    },
  ];

  const handleFormRedirect = () => {
    navigate('/user');
  };

  const handleEditItem = (itemId) => {
    navigate(`/user/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (result === 'confirm') {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un utilisateur
          </Button>
        </Grid>
      </Grid>
      <StatefulDataGrid
        defaultPageSize={30}
        columns={columns}
        rows={itemsList}
        boxSx={{ my: 2 }}
      />
    </Box>
  );
}
