import React from 'react';

import DiagnosticNeosylvaForm from '@/features/diagnostic-neosylva/DiagnosticNeosylvaForm';

import Container from '@mui/material/Container';

export default function DiagnosticNeosylvaCreate() {

  return (
    <Container component='main' maxWidth={false}>
      <DiagnosticNeosylvaForm />
    </Container>
  );
}
