import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';

import { setMessage } from '@/slices/messageSlice';
import { setIsLoading } from '@/slices/isLoadingSlice';
import { deleteItem } from '@/slices/itemSlice';

const FicheContratFormHeader = ({
  proprieteNom,
  proprieteId,
  codeChantier,
  contratId,
  onSubmit,
  disabledSubmit,
  goBackHistory
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { open } = useConfirmationDialog();

  const redirectTo = async (path, opts) => {
    const result = await open({
      content: 'Voulez-vous sauvegarder avant de quitter la fiche contrat ?',
      confirmButton: 'Oui',
      alternativeButton: 'Non',
    });

    if (result === 'confirm') {
      dispatch(setIsLoading(true));
      if (disabledSubmit) {
        dispatch(
          setMessage(
            'Sauvegarde impossible, veuillez remplir les champs obligatoires de la fiche contrat.'
          )
        );
        dispatch(setIsLoading(false));
        return;
      }
      onSubmit(false)
        .then(() => {
          navigate(path, opts);
        })
        .finally(() => dispatch(setIsLoading(false)));
    } else if (result === 'alternative') {
      navigate(path, opts);
    }
  };

  const redirectToTableauDeBordCMF = () => {
    redirectTo('/tableau-bord-cmf');
  };

  const redirectToFichePropriete = () => {
    redirectTo(`/fiche-propriete/${proprieteId}`, { replace: true });
  };

  const deleteContrat = async () => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression du contrat : ${codeChantier} ?`,
    });

    if (result === 'confirm') {
      dispatch(
        deleteItem({ itemId: contratId, itemType: 'contrats_proprietaires' })
      );
      goBackHistory();
    }
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link
          underline='hover'
          color='inherit'
          onClick={redirectToTableauDeBordCMF}
        >
          Tableau de bord CMF
        </Link>
        <Link
          underline='hover'
          color='inherit'
          onClick={redirectToFichePropriete}
        >
          Propriété {proprieteNom}
        </Link>
        <Typography sx={{ color: 'text.primary' }}>
          Contrat {codeChantier}
        </Typography>
      </Breadcrumbs>
      <Box
        mt={1}
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' component='div'>
          {contratId ? 'Édition' : 'Création'} fiche contrat
        </Typography>
        {contratId && (
          <Button
            onClick={deleteContrat}
            variant='outlined'
            startIcon={<DeleteIcon />}
          >
            Supprimer
          </Button>
        )}
      </Box>
    </>
  );
};

FicheContratFormHeader.propTypes = {
  proprieteNom: PropTypes.string,
  proprieteId: PropTypes.number,
  codeChantier: PropTypes.string,
  contratId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool.isRequired,
  goBackHistory: PropTypes.func.isRequired,
};

export default memo(FicheContratFormHeader);
