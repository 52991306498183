import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Clear from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';

/**
 * CustomGridToolBar component provides a toolbar for the DataGrid with quick filtering and reset capabilities.
 * 
 * It includes:
 * - A quick filter input that supports comma-separated search terms
 * - An optional reset button that appears when models are saved
 * 
 * The component is memoized to prevent unnecessary re-renders, especially when callback
 * functions like onReset change references but not functionality.
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.hasModel - Whether there are saved models that can be reset
 * @param {Function} props.onReset - Callback function to reset all grid models (filters, pagination, etc.)
 * @returns {JSX.Element} The toolbar component
 */
function CustomGridToolBar({ hasModel, onReset }) {
  return (
    <GridToolbarContainer>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} sm={6}>
          <GridToolbarQuickFilter
            debounceMs={500}
            quickFilterParser={(searchInput) =>
              searchInput.split(',').map((value) => value.trim())
            }
            quickFilterFormatter={(quickFilterValues) =>
              quickFilterValues.join(', ')
            }
          />
        </Grid>
        {hasModel && (
          <Grid item>
            <Button onClick={onReset} startIcon={<Clear />}>
              Réinitialiser
            </Button>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
}

CustomGridToolBar.propTypes = {
  hasModel: PropTypes.bool,
  onReset: PropTypes.func,
};

/**
 * Custom comparison function for React.memo optimization.
 * This function ensures that the component only re-renders when hasModel changes,
 * ignoring changes to the onReset function reference (which may change on parent re-renders).
 * 
 * @param {Object} prevProps - Previous component props
 * @param {Object} nextProps - Next component props
 * @returns {boolean} Whether the component should skip re-rendering
 */
function arePropsEqual(prevProps, nextProps) {
  return prevProps.hasModel === nextProps.hasModel;
}

export default memo(CustomGridToolBar, arePropsEqual);